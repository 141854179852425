import React, { useEffect, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { CentreContainer, setIdToken } from "@project/core";
import { RouteType, SideBarItems } from "@project/core/types";
import { captureException } from "@sentry/nextjs";
import { Layout, Menu, Row, Spin } from "antd";
import { signOut } from "next-auth/react";
import Head from "next/head";
import Link from "next/link";
import { useDispatch } from "react-redux";
import styles from "./DefaultLayout.styles";

const { Header, Content, Sider } = Layout;

export interface DefaultLayoutProps<R extends RouteType> {
  title: string;
  logo: JSX.Element;
  items: SideBarItems<R>;
  pathForResolvedURL: string | null;
  showTitle?: boolean;
  showMenu?: boolean;
  theme?: "light" | "dark";
  children?: JSX.Element | JSX.Element[];
  buttonsElement?: JSX.Element | null;
  /**
   * Hook into right before the user is signed-out.
   */
  onSignOut?: () => void;
  className?: string;
}

const DefaultLayout = <R extends RouteType>({
  children,
  title,
  logo,
  items,
  pathForResolvedURL,
  showTitle = true,
  showMenu = true,
  theme = "dark",
  onSignOut,
  buttonsElement,
  className,
}: DefaultLayoutProps<R>): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<any>();

  /**
   * The signOut function provided by next-auth simply signs the user out of the application -
   * it but does not sign them out of the Auth0 session.
   * We want them to be signed out of both so we add a callback which redirects the user to our custom logout route
   * implemented at src/pages/api/auth/logout.ts
   */
  const handleSignOut = async (): Promise<void> => {
    setLoading(true);

    if (onSignOut) onSignOut();

    dispatch(setIdToken(null));

    try {
      await signOut({ callbackUrl: "/api/auth/logout" });
    } catch (e) {
      captureException(e);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <CentreContainer>
        <Spin size="large" />
      </CentreContainer>
    );
  }

  return (
    <Layout css={styles}>
      <Head>
        <title>{title}</title>
      </Head>

      <Header>
        <Row className="header-container">
          <div className="logo-container">
            <Link href={{ pathname: "/" }}>
              <div className="logo">{logo}</div>
            </Link>
            {showTitle && <p className={theme === "dark" ? "text-on-dark" : "text-on-light"}>{title}</p>}
          </div>
          {buttonsElement && <div className="buttons-container">{buttonsElement}</div>}
        </Row>
      </Header>

      <Layout>
        {showMenu ? (
          <Sider breakpoint="lg" collapsedWidth="0" width={256}>
            <Menu theme={theme}>
              {items.map(item => (
                <Menu.Item
                  key={item.text}
                  className={pathForResolvedURL === item.href.pathname ? "propel-menu-item-active" : ""}
                >
                  <Link href={item.href}>
                    <a>
                      {item.icon}
                      <span>{item.text}</span>
                    </a>
                  </Link>
                </Menu.Item>
              ))}

              <Menu.Item onClick={handleSignOut} key="logout">
                <LogoutOutlined />
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          </Sider>
        ) : null}

        <Content className={className}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
