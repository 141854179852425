import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getCurrentUserApiV1UsersMeGet: build.query<
      GetCurrentUserApiV1UsersMeGetApiResponse,
      GetCurrentUserApiV1UsersMeGetApiArg
    >({
      query: () => ({ url: `/api/v1/users/me/` }),
    }),
    listViewableReportsApiV1ReportsGet: build.query<
      ListViewableReportsApiV1ReportsGetApiResponse,
      ListViewableReportsApiV1ReportsGetApiArg
    >({
      query: () => ({ url: `/api/v1/reports/` }),
    }),
    viewReportApiV1ReportsReportIdGet: build.query<
      ViewReportApiV1ReportsReportIdGetApiResponse,
      ViewReportApiV1ReportsReportIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/reports/${queryArg.reportId}/` }),
    }),
    submitReportApiV1ReportsSubmitReportPost: build.mutation<
      SubmitReportApiV1ReportsSubmitReportPostApiResponse,
      SubmitReportApiV1ReportsSubmitReportPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/reports/submit-report/`,
        method: "POST",
        body: queryArg.reportSubmitRequest,
        params: { reportId: queryArg.reportId },
      }),
    }),
    downloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGet: build.query<
      DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiResponse,
      DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/reports/${queryArg.reportId}/${queryArg.productId}/results/download` }),
    }),
    getCustomerFormApiV1CustomersCustomerIdFormGet: build.query<
      GetCustomerFormApiV1CustomersCustomerIdFormGetApiResponse,
      GetCustomerFormApiV1CustomersCustomerIdFormGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/customers/${queryArg.customerId}/form/` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiGenerated };
export type GetCurrentUserApiV1UsersMeGetApiResponse = /** status 200 Successful Response */ UserWithRoles;
export type GetCurrentUserApiV1UsersMeGetApiArg = void;
export type ListViewableReportsApiV1ReportsGetApiResponse = /** status 200 Successful Response */ ReportModel[];
export type ListViewableReportsApiV1ReportsGetApiArg = void;
export type ViewReportApiV1ReportsReportIdGetApiResponse =
  /** status 200 Successful Response */ ReportModelWithMappingAndSupplementOptions;
export type ViewReportApiV1ReportsReportIdGetApiArg = {
  reportId: string;
};
export type SubmitReportApiV1ReportsSubmitReportPostApiResponse =
  /** status 200 Successful Response */ ReportModelWithMappingAndSupplementOptions;
export type SubmitReportApiV1ReportsSubmitReportPostApiArg = {
  reportId: string;
  reportSubmitRequest: ReportSubmitRequest;
};
export type DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiResponse =
  /** status 200 Successful Response */ any;
export type DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiArg = {
  reportId: string;
  productId: Product;
};
export type GetCustomerFormApiV1CustomersCustomerIdFormGetApiResponse = /** status 200 Successful Response */ any;
export type GetCustomerFormApiV1CustomersCustomerIdFormGetApiArg = {
  customerId: string;
};
export type Role = "Admin" | "Dietitian" | "Head Dietitian";
export type UserWithRoles = {
  id?: string;
  firstName: string;
  lastName: string;
  roles?: Role[];
};
export type ReportStatus = "AWAITING_TEST_DATA" | "RECEIVED_TEST_DATA" | "DIETITIAN_REVIEWING" | "REPORT_READY";
export type Product = "METABOLITE" | "MICROBIOME";
export type ReportProductStatus = "AWAITING_TEST_DATA" | "RECEIVED_TEST_DATA" | "PROCESSED_TEST_DATA";
export type ReportProductModel = {
  productId: Product;
  statusId: ReportProductStatus;
};
export type Score = {
  score: number;
  comment: string;
};
export type SubScore = {
  score: number;
  comment: string;
  markers: {
    [key: string]: Score;
  };
};
export type HealthScore = {
  score: number;
  comment: string;
  subScores: {
    [key: string]: SubScore;
  };
};
export type HealthScores = {
  customerInfo: object | null;
  results: {
    [key: string]: HealthScore;
  };
};
export type SupplementRecommendation = {
  supplementId: number;
  supplementName: string;
  quantity: number;
};
export type ReportModel = {
  id: string;
  customerId: string;
  assignedUserId?: string;
  statusId: ReportStatus;
  viewed: boolean;
  products: ReportProductModel[];
  healthScores?: HealthScores;
  supplementRecommendations?: SupplementRecommendation[];
  created: string;
};
export type Marker =
  | "METABOLISM_MARKER"
  | "ENERGY_SCORE"
  | "NUTRITIONAL_SCORE"
  | "SLEEP_SCORE"
  | "ALCOHOL_INTAKE_SCORE"
  | "PHYSICAL_ACTIVITY_SCORE"
  | "ACETATE_PRODUCERS"
  | "CARDIAC_HEALTH"
  | "INFLAMMATION"
  | "IBS_GENERAL_CONSTIPATION"
  | "GUT_DISCOMFORT_BLOATING"
  | "WEIGHT_MANAGEMENT"
  | "PROPIONATE_PRODUCERS"
  | "BUTYRATE_PRODUCERS"
  | "GUT_GAS_PRODUCTION_LACTATE_PRODUCING"
  | "GUT_GAS_PRODUCTION_H2_PRODUCING"
  | "GUT_GAS_PRODUCTION_SULFATE_REDUCING_BACTERIA"
  | "GUT_GAS_PRODUCTION_LACTATE_DEGRADING"
  | "GUT_GAS_PRODUCTION_H1_USING"
  | "GUT_GAS_PRODUCTION_H2_USING"
  | "METHANE_PRODUCER"
  | "POTENTIAL_AUTOIMMUNE_TRIGGERS"
  | "WORMS"
  | "PARASITIC_ORGANISIMS"
  | "FUNGI"
  | "ARCHAEA"
  | "VIRUS"
  | "FATTY_ACID_METABOLISM"
  | "CARBOHYDRATE_METABOLISM"
  | "ENERGY_METABOLISM"
  | "B_COMPLEX_VITAMIN_MARKERS"
  | "NEUROTRANSMITTER_METABOLISM"
  | "OXIDATIVE_DAMAGE_AND_ANTIOXIDANT_MARKERS"
  | "DYSBIOSIS_MARKERS"
  | "OXALATE_METABOLITES"
  | "NUTRITIONAL_MARKERS";
export type SupplementOption = {
  id: number;
  code: string;
  name: string;
  text: string;
};
export type ReportModelWithMappingAndSupplementOptions = {
  id: string;
  customerId: string;
  assignedUserId?: string;
  statusId: ReportStatus;
  viewed: boolean;
  products: ReportProductModel[];
  healthScores?: HealthScores;
  supplementRecommendations?: SupplementRecommendation[];
  created: string;
  mapping?: {
    [key: string]: {
      [key: string]: Marker[];
    };
  };
  supplementOptions?: SupplementOption[];
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ReportSubmitRequest = {
  healthScores: HealthScores;
  supplementRecommendations: SupplementRecommendation[];
};
