import { DefaultLayout, DefaultLayoutProps, processSideBarItems } from "@project/core";
import { AppConfig, RouteType } from "@project/core/types";
import { Actor } from "../../../utils/authorization";

export interface LayoutWrapperProps<R extends RouteType> extends Omit<DefaultLayoutProps<R>, "items"> {
  appConfig: AppConfig<R>;
  iconParamsMap?: Record<string, unknown>;
  actor: Actor;
  buttonsElement?: JSX.Element | null;
  className?: string;
}

const LayoutWrapper = <R extends RouteType>({
  appConfig,
  iconParamsMap,
  actor,
  children,
  buttonsElement,
  ...defaultLayoutProps
}: LayoutWrapperProps<R>): JSX.Element => {
  return (
    <DefaultLayout
      items={processSideBarItems(appConfig, iconParamsMap ?? {}, actor)}
      buttonsElement={buttonsElement}
      {...defaultLayoutProps}
    >
      {children}
    </DefaultLayout>
  );
};

export default LayoutWrapper;
