import { ConfigProvider } from "antd";
import { SessionProvider } from "next-auth/react";
import { Provider } from "react-redux";
import { wrapper } from "store";
import type { AppProps } from "next/app";

import "antd/dist/antd.variable.min.css";
import "@project/core/styles/global.css";
import "styles/override.css";

ConfigProvider.config({
  theme: {
    primaryColor: "#fadb14",
  },
});

const App: React.FC<AppProps> = ({ Component, ...rest }) => {
  const {
    store,
    props: { session, ...pageProps },
  } = wrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <SessionProvider session={session}>
        <Component {...pageProps} />
      </SessionProvider>
    </Provider>
  );
};

export default App;
