import { authReducer } from "@project/core";
import { generateRTKErrorLogger } from "@project/core/utils/errorLogger";
import { configureStore } from "@reduxjs/toolkit";
import { StatusCodes } from "http-status-codes";
import { createWrapper } from "next-redux-wrapper";
import { sapientApi } from "./api/apiSlice";

type Endpoints = keyof typeof sapientApi.endpoints;

const apiErrorMessageMapper = (
  endpointName: Endpoints,
  status: StatusCodes,
): { message: string; unexpected: boolean } => {
  switch (endpointName) {
    case "getCurrentUserApiV1UsersMeGet":
      switch (status) {
        case StatusCodes.NOT_FOUND:
        case StatusCodes.UNAUTHORIZED:
          return { message: "User does not exist", unexpected: false };
      }
      break;
    case "viewReportApiV1ReportsReportIdGet":
      return { message: "Failed to load Report", unexpected: true };
    case "submitReportApiV1ReportsSubmitReportPost":
      return { message: "Failed to submit Report", unexpected: true };
  }
  return { message: "Something went wrong", unexpected: true };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeStore = () =>
  configureStore({
    reducer: {
      [sapientApi.reducerPath]: sapientApi.reducer,
      auth: authReducer,
    },
    devTools: false,
    middleware: gDM =>
      // Inject the req/res context into the middleware,
      // so it can be accessed when preparing headers in baseApi.ts
      gDM().concat([sapientApi.middleware, generateRTKErrorLogger(apiErrorMessageMapper)]),
  });

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
