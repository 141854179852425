import {
  DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiArg,
  DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiResponse,
  apiGenerated as api,
} from "./apiGenerated";

api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    downloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGet: build.query<
      DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiResponse,
      DownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/reports/${queryArg.reportId}/${queryArg.productId}/results/download`,
        responseHandler: async response => {
          const resBlob = await response.blob();
          const blobUrl = window.URL.createObjectURL(resBlob);

          return { blobUrl };
        },
        cache: "no-cache",
      }),
    }),
  }),
});

export const sapientApi = api.enhanceEndpoints({
  addTagTypes: ["User", "Report", "Me"],
  endpoints: {
    getCurrentUserApiV1UsersMeGet: {
      providesTags: result => (result ? [{ type: "User" as const, id: result.id }, "User"] : ["User"]),
    },
    listViewableReportsApiV1ReportsGet: {
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: "Report" as const, id })), "Report"] : ["Report"],
    },
    viewReportApiV1ReportsReportIdGet: {
      providesTags: result => (result ? [{ type: "Report" as const, ReportId: result.id }] : ["Report"]),
    },
    submitReportApiV1ReportsSubmitReportPost: {
      invalidatesTags: result => (result ? [{ type: "Report" as const, ReportId: result.id }] : ["Report"]),
    },
    getCustomerFormApiV1CustomersCustomerIdFormGet: {},
    downloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGet: {},
  },
});

export const {
  useGetCurrentUserApiV1UsersMeGetQuery,
  useListViewableReportsApiV1ReportsGetQuery,
  useViewReportApiV1ReportsReportIdGetQuery,
  useSubmitReportApiV1ReportsSubmitReportPostMutation,
  useLazyGetCustomerFormApiV1CustomersCustomerIdFormGetQuery: useLazyGetCustomerForm,
  useLazyDownloadReportResultsApiV1ReportsReportIdProductIdResultsDownloadGetQuery: useLazyDownloadReportProductData,
} = sapientApi;
