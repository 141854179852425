import { getAppConfig } from "@project/core/utils/nextPublicConfigRuntime";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { captureException } from "@sentry/nextjs";
import { HYDRATE } from "next-redux-wrapper";
import { RootState } from "store";
import { appConfigParams } from "utils/nextPublicConfigRuntime";

const baseQuery = fetchBaseQuery({
  baseUrl: getAppConfig(appConfigParams).NEXT_PUBLIC_API_URL,
  prepareHeaders: (headers, { getState }): Headers => {
    try {
      const state = getState() as RootState;
      // If we have a token set in state, let's assume that we should be passing it.
      headers.set("authorization", `Bearer ${state.auth.idToken}`);
    } catch (e) {
      captureException(e);
    }

    return headers;
  },
});

export const baseApi = createApi({
  baseQuery: baseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
});
