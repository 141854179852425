import { css } from "@emotion/react";

export default css`
  .ant-layout-header {
    background: var(--primary);
    padding-inline: 0px;
  }

  .ant-layout-sider,
  .ant-menu,
  .ant-layout-sider-children {
    background: var(--sidebar);
  }

  /* Don't force sidebar width if the sidebar is collapsed */
  .ant-layout-sider:not(.ant-layout-sider-collapsed) {
    .ant-layout-sider-children {
      width: var(--sidebar-width);
    }
  }

  .ant-layout-content {
    padding: 16px 8px;
    background-color: white;
    overflow-y: auto;
  }

  .ant-layout-header {
    p {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .ant-menu-item {
    height: var(--menu-item-height);
    line-height: var(--menu-item-height);

    * {
      font-size: var(--menu-item-font-size);
    }
  }

  .logo-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 50%;
  }

  .logo {
    height: 64px; // same height as ant-layout-header
    display: flex;
    align-items: center;
    width: var(--sidebar-width);
    margin-right: 16px;
    cursor: pointer;
  }

  .ant-table-wrapper {
    max-height: 76vh;
    overflow-y: hidden;
  }

  .ant-table {
    margin-left: 0.5rem;
    margin-right: 1rem;
    overflow-y: scroll;
  }

  .ant-table-pagination {
    margin-left: 0.5rem;
    margin-right: 1rem;
    justify-content: center;
  }

  .header-container {
    display: flex;
    justify-content: end;
  }

  .buttons-container {
    margin-right: 20px;
  }

  .workbench-ant-layout {
    padding: 0 !important;
    margin: 0 !important;
  }

  .flex-spacer {
    display: flex;
  }
`;
